import Accordion from "../../components/accordion/accordion";
import {onDomReady} from "../../components/dynamic/observer";

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    new Accordion();
  }, 2000);
});

onDomReady(() => {
  new Accordion();
});


/**
 * Пример:
 *
 *  <ul data-accordion-container>   <-- Контейнер
 *    <li data-accordion-element>   <-- Элемент
 *      <a data-accordion-link>     <-- Переключатель
 *        Развернуть
 *      </a>
 *
 *      <div data-accordion-block>  <-- Блок (задать транзишен на высоту)
 *        <div>
 *          (много текста)
 *        </div>
 *      </div
 *    </li>
 *  </ul>
 */

/**
 * Менеджер аккордионов для единичной инициализации на странице и управления всеми аккордионами
 * Собирает аккордионы и отслеживает появление новых при изменениях на странице
 * Отслеживает ресайз страницы и передает его всем аккордионам для передачи в елементы
 *
 * ПОБОЧНО:
 * AccordionOpen - событие, отправляемое на документ, при разворачивании элемента аккордиона
 *  Передаваемые параметры:
 *    container - аккордион
 *    element - елемент аккордиона
 *
 * AccordionClose - событие, отправляемое на документ, при сворачивании элемента аккордиона
 * Передаваемые параметры:
 *    container - аккордион
 *    element - елемент аккордиона
 *
 * О работе с аккордионом и элементами смотрим классы Container и Element
 */

/**
 * Контейнер - сущность списка элементов аккордиона
 * т.е. это и есть аккордион
 *
 * ОБЯЗАТЕЛЬНО:
 * [data-accordion-container] - вешается на элемент, внутри которого элементы аккордиона
 *
 * ДОПОЛНИТЕЛЬНО:
 * [data-accordion-mode="<режим>"] - список режимов:
 *  single (поумолчанию) - при раскрытии одного элемента, закрывается открытый
 *  multi - можно раскрыть любое количество элементов
 *
 * ПОБОЧНО:
 * ContainerElementOpen - событие, отправляемое на контейнер при разворачивании элемента с передачей элемента
 *
 * ContainerElementClose - событие, отправляемое на контейнер при сворачивании элемента с передачей элемента
 *
 * ПРИМЕР РАЗМЕТКИ КОНТЕЙНЕРА:
 *  <ul data-accordion-container data-accordion-mode="multi">
 *    <li...
 *    (Список элементов. Примеры в классе Element)
 *    ...</li>
 *  </ul>
 *
 *
 * О работе с элементами смотрим класс Element
 */

/**
 * Елемент аккордиона
 *
 * ОБЯЗАТЕЛЬНО:
 * [data-accordion-element] (общий элемент) - должен быть на элементе
 *
 * [data-accordion-link] (переключатель) - должен быть на ссылке внутри общего элемента
 *
 * [data-accordion-block] (прячущий элемент (overflow: hidden)) -
 *  должен содержать только одного потомка и быть внутри общего элемента
 *
 * ДОПОЛНИТЕЛЬНО:
 * [data-is-opened="true"] - можно повесить на элемент, что бы он раскрылся при инициализации
 *
 * ПОБОЧНО:
 * ._opened - вешается на элемент при раскрытии и убирается при закрытии
 *
 * ElementOpen - событие отправляемое на элемент при раскрытии
 *
 * ElementClose - событие отправляемое на элемент при закрытии
 *
 * ПРИМЕР РАЗМЕТКИ ЭЛЕМЕНТА:
 *  <li data-accordion-element data-is-opened="true">
 *    <a data-accordion-link>
 *      Развернуть
 *    </a>
 *
 *    <div data-accordion-block>
 *      <div>
 *        (много текста)
 *      </div>
 *    </div
 *  </li>
 */