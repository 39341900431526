import { onDomReady } from '../components/dynamic/observer';
function hideLinks() {
    setTimeout(() => {
        const saveFromLinks = document.querySelectorAll("a[title='Получи прямую ссылку']");
        if (saveFromLinks) {
            saveFromLinks.forEach((link) => {
                link.closest('span').style.display = 'none';
            })
        }
    }, 2000);
}

onDomReady(() => {
    hideLinks();
});

