import { onDomReady } from '../components/dynamic/observer';
function move() {
    setTimeout(() => {
        var footer = document.querySelector('footer');
        var footerToTop = footer.getBoundingClientRect().top
        var widgetElement = document.getElementById('widget-animation-x');
        addEventListener("scroll", () => {
            const vertical = window.scrollY
            if (vertical > (footerToTop - window.innerHeight)) {
                widgetElement.style.bottom = '200px';
            } else {
                widgetElement.style.bottom = "40px";
            }
        })
    }, 2000);
}

onDomReady(() => {
    move();
});

