import { onDomReady } from '../../components/dynamic/observer';
import {scrollToTarget} from "../../components/scroll/scroll";
class Header {
    constructor(selector) {
        this.header = selector;
        if (window.pageYOffset > 50) {
            this.header.classList.add('_sticky');
        }
        this.headerHeight = this.header.offsetHeight / 2;
        this.headerContainer = this.header.querySelector('[data-header-container]');
        this.headerInner = this.header.querySelector('[data-header-inner]');
        this.dropMenu = selector.querySelector('[data-drop-menu]');
        this.dropMenuLinks = this.header.querySelectorAll('[data-drop-menu-link]');

        this.hamburger = selector.querySelector('[data-hamburger]');
        this.footer = document.querySelector('[data-footer]');

        this.aboutMenu = document.querySelector('[data-about-menu]');
        this.closer = document.querySelector('[data-closer]');
        this.isOpened = false;
        this.scrollTop = 0;

        this.eventListeners();
    }

    open() {
        this.isOpened = true;
        this.header.classList.add('_opened');
        this.dropMenu.classList.add('_opened');
        this.hamburger.classList.add('_opened');
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
    }

    close() {
        this.isOpened = false;
        this.header.classList.remove('_opened');
        this.dropMenu.classList.remove('_opened');
        this.hamburger.classList.remove('_opened');
        document.body.style.overflow = '';
        document.body.style.height = '';
    }

    addSticky() {
        const instance = this;
        if (instance.scrollTop > instance.headerHeight) {
            this.header.classList.add('_sticky');
        } else {
            instance.header.classList.remove('_sticky');
        }

        // if - 50% (instance.scrollTop > 0) {
        //     this.headerContainer.classList.add('_sticky');
        // } else {
        //     instance.headerContainer.classList.remove('_sticky');
        // }
    }

    handleHeader() {

    }

    updateFooterPosition() {
        const instance = this;
        if (instance.footer) {
            instance.footerTop = instance.footer.getBoundingClientRect().top;
        }
    }

    translateHeaderByFooter() {
        const instance = this;
        if (instance.footer) {
            const offset = instance.headerInner.offsetHeight - instance.footerTop;
            if (offset > 0) {
                instance.headerInner.style.transform = `translate3d(0, -${offset}px, 0)`;
            } else {
                instance.headerInner.style.transform = '';
            }
        }
    }

    eventListeners() {
        this.hamburger.addEventListener('click', () => {
            this.isOpened ? this.close() : this.open();
        });

        document.addEventListener('click', (e) => {
            if (this.isOpened && this.dropMenu === e.target) {
                this.close();
            }
        });

        this.closer.addEventListener('click', (e) => {
            this.close();
        });

        window.addEventListener('scroll', () => {
            const instance = this;
            instance.scrollTop = window.scrollY;
            instance.updateFooterPosition();

            instance.addSticky();

            instance.handleHeader();
            instance.translateHeaderByFooter();

            instance.lastScrollTop = instance.scrollTop;
        });

        this.dropMenuLinks.forEach((item) => {
            item.addEventListener('click', () => {
                if (!this.isOpened) {
                    const target = document.querySelector(item.dataset.dropMenuLink);
                    const offset = 0;

                    scrollToTarget(target, offset);
                } else {
                    this.close();
                    setTimeout(() => {
                        const target = document.querySelector(item.dataset.dropMenuLink);
                        const offset = 0;

                        scrollToTarget(target, offset);
                    }, 500);
                }
            });
        });
    }
}

onDomReady(() => {
    const headerSelector = document.querySelector('[data-header]');
    if (headerSelector) {
        new Header(headerSelector);
    }
});